import * as msal from "@azure/msal-browser";
import { settingsNS } from "./settings";

export class AuthService {
    private msalConfig: msal.Configuration;
    private msalInstance: msal.PublicClientApplication;
    private scope: string[];
    
    constructor(clientId: string, redirectUri: string, scope: string[]) {
        this.scope = scope;

        this.msalConfig = {
            auth: {
                clientId: clientId,
                authority: 'https://login.microsoftonline.com/a8f2ac6f-681f-4361-b51f-c85d86014a17',
                redirectUri: redirectUri,
                postLogoutRedirectUri: `${redirectUri}/home`
            },
            cache: {
                cacheLocation: "sessionStorage",
                "storeAuthStateInCookie": true
            }
        };
        this.msalInstance = new msal.PublicClientApplication(this.msalConfig);
    }

    async verifyLoginInProgress() : Promise<msal.AuthenticationResult> {
        const authResult = await this.msalInstance.handleRedirectPromise();
        console.log("Login Result is ", authResult);
        if (authResult) {
            this.msalInstance.setActiveAccount(authResult.account);
        }
        return authResult;
    }

    login() {
        try {
            this.msalInstance.loginRedirect(
                {
                    scopes: this.scope
                }
            );
        } catch (error) {
            //eslint-disable-next-line
            console.log("loging", error);
        }
    }

    logout() {
        try {
            this.msalInstance.logoutRedirect({
                onRedirectNavigate: (/*url*/) => {
                    // Return false if you would like to stop navigation after local logout
                    return false;
                }
            });
        } catch (error) {
            //eslint-disable-next-line
            console.log("logout", error);
        }
    }

    getActiveAccount(): msal.AccountInfo {
        const account = this.msalInstance.getActiveAccount();
        console.log("account is ", account);
        return account;
    }

    async getToken() : Promise<string> {
        const request = {
            scopes: this.scope,
        };
        let token = "";
        try {
            const tokenResponse = await this.msalInstance.acquireTokenSilent(request);
            token = tokenResponse.accessToken;
            return token;

        } catch (error) {
            if (error instanceof msal.InteractionRequiredAuthError) {
                // fallback to interaction when silent call fails
                if (error.errorCode == "no_tokens_found") {
                    //needs new login
                    throw error;
                }
                else this.msalInstance.acquireTokenRedirect(request)
            }
        }
        return token;
    }

}



export class authenticationNS {
    static auth : AuthService = new AuthService(settingsNS.clientID, settingsNS.redirectUri, settingsNS.scope);
} 