import {RestWrapper} from "./REST/RestWrapper"; 
import { settingsNS } from "./settings";
import { Company, Configuration, Station, StationsForCompany } from "./types";

class configNS {
    static rest : RestWrapper = new RestWrapper();
} 

async function getConfiguration() : Promise<Configuration> {
    const result = <Configuration> await configNS.rest.callRestGET(`${settingsNS.baseUrl}/initialize`, settingsNS.stdHeader);
    return result;
} 

async function getCompanyForUser(username: string): Promise<Company> {
    const config = await getConfiguration();
    const matchingCompany = config.companies.filter(item => item.user.toUpperCase() == username.toUpperCase());
    return matchingCompany.length > 0 ? matchingCompany[0] : null;
}

async function getStationsForCompany(company: Company) : Promise<Station[]> {
    const result = <StationsForCompany[]> await configNS.rest.callRestGET(`${settingsNS.baseUrl}/company`, settingsNS.stdHeader);
    const relevantStations = result.filter(item => item.company == company.name && item.country == company.country);
    return relevantStations.length > 0 ? relevantStations[0].stations : [];
} 

export {getConfiguration, getCompanyForUser, getStationsForCompany}