
  import { Company } from '@/service/types';
import store from '@/store/store';
  import { Component, Vue } from 'vue-property-decorator';
  import { Getter } from 'vuex-class';

  @Component
  export default class TopToolbar extends Vue {
    showMenu = false;

    @Getter("title", { namespace: 'topToolbar' }) title!: string;
    @Getter("company", { namespace: 'user' }) company!: Company;

    toggleMenu(): void {
      this.showMenu = !this.showMenu;
    }
    onRefresh(): void {
      store.dispatch("stations/get");
    }
  }
