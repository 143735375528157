import { getStationsForCompany } from '@/service/configuration';
import { Station } from '@/service/types';
import { ActionTree, Module, MutationTree, GetterTree } from 'vuex';
import { RootState, StationState } from '../../types';

const state: StationState = {
  stations: []
};

export const getters: GetterTree<StationState, RootState> = {
  stations: (theState: StationState) => {
    return theState.stations;
  },
};

const mutations: MutationTree<StationState> = {
  setStations(theState: StationState, stations: Station[]) {
    theState.stations = stations.map(item => {
      return {...item, text: `${item.siteId} - ${item.address}`}
    });
    console.log(theState.stations);
  }
};

export const actions: ActionTree<StationState, RootState> = {
  async get({commit, state, rootState}) {
    if (rootState.user.company) {
      const stations = await getStationsForCompany(rootState.user.company);
      commit('setStations', stations);
    }
  }
};

export const stations: Module<StationState, RootState> = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};