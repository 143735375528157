export class settingsNS {
    //For Production Environment
    static baseUrl = "https://fuelsupply-prod-fa.azurewebsites.net/api";
    static stdHeader = {
        "x-functions-key": "sBGHba856BhYMIn0voakdunPMQGFJX5/AED7F5YCJIETR9sc/42qxQ=="
    }
    static clientID = '9bf9b9f5-4fc4-4ac6-aa71-0033800b18ff'
    static redirectUri = "https://dipstick.omv.com"; //will be added by Azure Replace Token Task
    static scope = ["api://fuelsupply-web.omv.com/Read.User"];
    static environment = "ProdEnv"
}

//development environment
if (settingsNS.environment != "TestEnv" && settingsNS.environment != "ProdEnv") {
    settingsNS.redirectUri = "http://localhost:8080";
    settingsNS.baseUrl = "https://fuelsupply-dev-fa.azurewebsites.net/api";
    settingsNS.stdHeader = {
        "x-functions-key": "sBGHba856BhYMIn0voakdunPMQGFJX5/AED7F5YCJIETR9sc/42qxQ=="
    }
}
//Test Environment
if (settingsNS.environment == "TestEnv") {
    settingsNS.baseUrl = "https://fuelsupply-dev-fa.azurewebsites.net/api";
    settingsNS.stdHeader = {
        "x-functions-key": "2ZdUa8vKPaPRHpvRe5OLhg92Co3GalkhnNG9n/yGIq0sTKJdvrZKaA=="
    }
}