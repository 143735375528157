import { ActionTree, Module, MutationTree, GetterTree } from 'vuex';
import { UserState as UserState, RootState } from '../../types';
import { authenticationNS } from '@/service/authentication';
import { UserAccount } from '@/service/user';
import { getCompanyForUser } from '@/service/configuration';
import { Company } from '@/service/types';
import Vue from 'vue'

const state: UserState = {
  company: null,
  user: null
};

export const getters: GetterTree<UserState, RootState> = {
  company: (theState: UserState) => {
    return theState.company;
  },
  user: (theState: UserState) => {
    return theState.user;
  }
};

const mutations: MutationTree<UserState> = {
  setCompany(theState: UserState, company: Company) {
    theState.company = company;
  },
  setUser(theState: UserState, user: UserAccount) {
    theState.user = user;
  },
};

export const actions: ActionTree<UserState, RootState> = {
  changeCompany({commit}, company: Company) {
    commit('setCompany', company);
  },
  changeUser({commit}, user: UserAccount) {
    commit('setUser', user);
  },
  logout({commit}) {
    commit('setCompany', null);
    commit('setUser', null);
    authenticationNS.auth.logout();
    Vue["$vToastify"].success("Successfully logged out.");
  },
  login() {
    authenticationNS.auth.login();
  },
  async loginSuccess({commit}, user: UserAccount) {
    commit('setUser', user);
    const company = await getCompanyForUser(state.user.email);
    commit('setCompany', company);
  }
};

export const user: Module<UserState, RootState> = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};