
  import { Component, Vue } from 'vue-property-decorator';
  import { Getter } from 'vuex-class';

  @Component
  export default class BottomNav extends Vue {
    activeItem = 'login';
    
    @Getter("company", { namespace: 'user' }) company!: string;

  }
