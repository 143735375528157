export class UserAccount {
    name: string;
    msAccountId: string;
    role: string;
    email: string;
    constructor(name: string, msAccountId: string, email: string, role: string) {
        this.name = name;
        this.msAccountId = msAccountId;
        this.role = role;
        this.email = email;
    }

    isAdmin() {
        return this.role == "Admin";
    }
}