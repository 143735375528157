import Vue from 'vue'
import VueRouter, { Route, RouteConfig } from 'vue-router'
import store from '../store/store';
import i18n from '../i18n'
import { authenticationNS } from '@/service/authentication';
import { UserAccount } from '@/service/user';

Vue.use(VueRouter)

class RouteMeta {
  title: string;

  constructor({title}: { title: string }) {
    this.title = title;
  }
}

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'home',
    redirect: {name: 'login'}
  },
  {
    path: '/About',
    name: 'about', 
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
    meta: new RouteMeta({ title: i18n.t("About.title").toString() })
  },
  {
    path: '/Stations',
    name: 'stations',
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "main" */ '../views/Stations.vue'),
    meta: new RouteMeta({ title: i18n.t("Stations.title").toString() })
  },
  {
    path: '/Maps',
    name: 'maps',
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "main" */ '../views/Maps.vue'),
    meta: new RouteMeta({ title: i18n.t("Maps.title").toString() })
  },
  {
    path: '/Login',
    name: 'login',
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "main" */ '../views/Login.vue'),
    meta: new RouteMeta({ title: i18n.t("Login.title").toString() })
  },
  {
    path: '/Settings',
    name: 'settings',
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "main" */ '../views/Settings.vue'),
    meta: new RouteMeta({ title: i18n.t("Settings.title").toString() })
  },
  {
    path: '/Logout',
    name: 'logout',
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "main" */ '../views/Login.vue'),
    meta: new RouteMeta({ title: i18n.t("Login.title").toString() })
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(async (to, from, next) => {
  console.log(to.name);
  
  await setupUserAccount();
  console.log("User details", store.state.user.user, "Going to:", to, "logged in?", isLoggedIn());

  if (to.name == "logout") {
    store.dispatch("user/logout");
    next({ name: 'login' });
  }
  else if (to.name == "login") {
    if (isLoggedIn()) {
      console.info("already logged in - redirect to stations page");
      next({name: "stations"});
    }
    else {
      setTitle(to);
      next();
    }
  }
  else {
    if (isLoggedIn()) {
      setTitle(to);
      next();
    }
    else {
      console.info("Not authenticated");
      Vue["$vToastify"].error("Please login.", "Authorization Error");
      next({ name: 'login' });
    }
  }
});

function setTitle(to: Route) {
  const routeMeta = to.meta as RouteMeta;
  store.dispatch('topToolbar/changeTitle', routeMeta.title);
}

function isLoggedIn() {
  return store.state.user.user != undefined;
}

async function setupUserAccount() {
  try {
    await authenticationNS.auth.verifyLoginInProgress();
  } catch (err) {
    console.error("handleRedirectPromise", err);
  }

  if (!store.state.user.user) {
    const account = authenticationNS.auth.getActiveAccount();
    if (account) {
      const roles = account.idTokenClaims["roles"];
      console.log(roles);
      const relevantRole = roles && roles.length > 0 ? roles[0] : "";
      console.log("relevant role", relevantRole);
      await store.dispatch("user/loginSuccess", new UserAccount(account.name, account.homeAccountId, account.username, relevantRole));
      await store.dispatch("stations/get");
    }
  }
}

export default router;