import fetch from 'cross-fetch'; 

export class RestWrapper {
    //private axios;
    
    constructor() {
        //this.axios = require('axios').default;
    }

    /**
     * This function performs a HTTP call to the url provided. The response is parsed to a JSON object.
     * In case the parsing fails, an Error is thrown.
     * @param url URL to call
     * @param header HEADER fields in the HTTP Call
     * @returns result as (JSON) Object; 
     */
    async callRestGET(url: string, header = {}) : Promise<unknown> {
        let resultJSON : unknown = null;
        const response = await this.httpGET(url, header);
        if (response.ok) {
            try {
                const queryResponseAsString = await response.text();
                if (queryResponseAsString.length != 0) {
                    resultJSON = JSON.parse(queryResponseAsString);
                }
            } catch (err) {
                console.log(err);
            }
        }
        else {
            const queryResponseAsString = await response.text();
            throw new Error(`Query not successful, HTTP Result ${response.status}, response: ${queryResponseAsString}`)
        }
        return resultJSON;
    }
    
    /**
     * 
     * @param url This function perofrms a call to the url provided. The result is the response of the HTTP Call without any parsing.
     * @param header HEADER fields in the HTTP Call
     * @returns response of HTTP call
     */
    async httpGET(url: string, header = {}) : Promise<any> {
        console.log(url);
        let response = null;
        try {
            response = await fetch( 
                url,
                {
                    headers: { 
                        'Content-Type': 'application/json',
                        "x-audience": "internal", //only necessary for RISI calls to get all relevant fields
                        ...header
                    },  
                    method: 'GET',
                }
            );
            
        } catch (err) {
            console.log(err);
        }
        return response;
    }
    
    async callRestPOST(url: string, body, header = {}) {
        let resultJSON = null;
        
        const response = await this.httpPOST(url, body, header);
        const queryResponseAsString = await response.text();
        if (response.ok) {
            try {
                if (queryResponseAsString.length != 0) {
                    resultJSON = JSON.parse(queryResponseAsString);
                }
            } catch (err) {
                console.log(err);
            }
        }
        else {
            throw new Error(`Query not successful, HTTP Result ${response.status}, response: ${queryResponseAsString}`)
        }
        return resultJSON;
    }
    
    async callPOST(url:string, body: string, header = {}) {
        const response = await this.httpPOST(url, body, header);
        if (!response.ok) {
            const queryResponseAsString = await response.text();
            throw new Error(`Query not successful, HTTP Result ${response.status}, response: ${queryResponseAsString}`);
        }
        return response.status;
    }
    
    async httpPOST(url:string, body: string, header = {}) {
        console.log(url);
        let response = null;
        try {
            response = await fetch(
                url,
                {
                    headers: { 
                        'Content-Type': 'application/json',
                        "x-audience": "internal", //only necessary for RISI calls to get all relevant fields
                        ...header
                    },  
                    method: 'POST',
                    body: body
                }
            );
            
        } catch (err) {
            console.log(err);
        }
        return response;
    }
}
