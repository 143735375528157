import Vue from 'vue';
import Vuex, { StoreOptions } from 'vuex';
import { RootState } from '../types';

import { topToolbar } from './modules/topToolbar';
import { user } from './modules/user';
import { stations } from './modules/stations';

Vue.use(Vuex);

const store: StoreOptions<RootState> = {
  modules: {
    topToolbar,
    user,
    stations
  }
};

export default new Vuex.Store<RootState>(store);